<template>
  <b-card title="Events">

    <b-tabs>

      <b-tab>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <a href="">Add Event</a>
        </template>

        <AddEvent @add-category="getData" />
      </b-tab>
      <b-tab @click="$router.push('/all-events')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/all-categories">All Categories</a> -->
          <span>All Events</span>
        </template>
        <router-view />
        <!-- <EventsList ref="allData"></EventsList> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import AddEvent from './AddEvent.vue'

export default {
  components: {

    BCard,
    BTabs,
    BTab,

    AddEvent,
  },
  setup() {
    const allData = ref()

    const getData = () => {

      // allData.value.getEvents()

    }
    return {
      getData,
      allData,

    }
  },
}
</script>
